<script setup lang="ts">
type TocItem = {
  id: string
  text: string
  depth: number
  children: TocItem[]
}

const props = defineProps<{
  node: TocItem
}>()
</script>

<template>
  <li class="toc-item">
    <a :href="`#${props.node.id}`" :class="`level-${node.depth}`" class="title-font">
      {{ node.text }}
    </a>
    <ul class="toc">
      <TableOfContentsItem v-for="child in node.children" :key="child.id" :node="child" />
    </ul>
  </li>
</template>

<style lang="postcss">
.toc-item {
  font-family: var(--font-stack-title);
  font-variation-settings: 'wdth' 5;
  margin-left: 0;
  line-height: 1.2;

  &.highlight > a {
    color: var(--secondary);
    font-weight: 500;
  }

  .level-4 {
    font-size: 0.9em;
  }

  .level-5 {
    font-size: 0.9em;
  }

  .level-6 {
    font-size: 0.9em;
  }
}
</style>
