<script setup lang="ts">
import SidebarLink from './SidebarLink.vue'
import SearchBar from './SearchBar.vue'
import { sortLinks } from './util'

const { navigation } = useContent()
const dm = useDM()

const open = ref(false)

const withoutIndex = computed(() => {
  const nodes = navigation.value.filter((item) => item._path !== '/')
  return sortLinks(nodes, dm.value)
})

const dmCookie = useCookie('dungeonMaster', { maxAge: 60 * 60 * 24 * 365 })
const dmPassword = () => {
  const password = prompt('DM says what?')
  if (password) dmCookie.value = password
}
</script>

<template>
  <nav class="menu" :class="{ open }">
    <div class="menu-content">
      <img src="/img/ornament.png" alt="ornament" @dblclick="dmPassword" />
      <SearchBar />
      <NuxtLink to="/" class="index-link">
        <h3>The Estian Islands</h3>
      </NuxtLink>
      <ClientOnly>
        <ul class="node-list">
          <SidebarLink v-for="node in withoutIndex" :key="node._id" :node="node" />
        </ul>
      </ClientOnly>
    </div>
    <div class="opener" @click="open = !open"></div>
  </nav>
</template>

<style scoped lang="postcss">
.menu {
  grid-area: menu;
  max-width: var(--sidebar-width);

  padding: 1em;

  h3 {
    font-size: 1.25em;
    font-weight: 500;
    width: 100%;
    display: inline-block;
    text-align: center;
    color: var(--primary);
    margin-bottom: 0.25em;
    margin-top: 1em;
  }

  .menu-content > .node-list {
    padding-left: 0;
  }

  @media (max-width: 950px) {
    position: fixed;
    flex: initial;
    background: var(--background-light);
    border-right: 2px solid var(--primary);
    top: 0;
    height: 100vh;
    width: 300px;
    max-width: 80vw;
    left: -300px;
    z-index: 500;
    transition: left 1s ease-in;

    &.open {
      left: 0;
      box-shadow: var(--card-shadow);
    }

    .menu-content {
      overflow-y: auto;
      max-height: 100%;
    }

    .opener {
      z-index: 400;
      border: 2px solid var(--primary);
      background: var(--background-light);
      border-left-width: 1px;
      border-radius: 0 5px 5px 0;
      width: 1.5em;
      height: 3em;
      position: absolute;
      right: -1.5em;
      top: 1em;
    }
  }
}
</style>

<style lang="postcss">
.menu {
  .router-link-exact-active {
    font-weight: 500;
    --primary: var(--secondary);
  }

  .node-list {
    list-style: none;
    padding-left: 0.75em;
    margin-bottom: 0.5em;
    margin-top: 0;
  }
}
</style>
